import React, { useState } from "react";
import { Col, Progress, Row, Upload, message, notification } from "antd";
import { Image } from "antd";
import type { RcFile } from "antd/es/upload";
import type {
  UploadChangeParam,
  UploadFile,
  UploadFileStatus,
} from "antd/es/upload/interface";
import axios from "axios";
import { convertImageToPng, getBase64, getPresignedUrl } from "../utils/common";

type UploadCompProps = {
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  filesDataArray: {
    fileFor: string;
    label: string;
    defaultImage: string;
    width?: string;
    height?: string;
    required: boolean;
  }[];
};

const UploadComp: React.FC<UploadCompProps> = ({
  fileList,
  setFileList,
  filesDataArray,
}) => {
  const [progress, setProgress] = useState<{ [key: string]: number }>({});
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [msg, contextHolder] = message.useMessage();
  const [localPreviews, setLocalPreviews] = useState<{ [key: string]: string }>(
    {}
  );

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || file.preview || "");
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleUpload = async (file: RcFile, fileFor: string) => {
    try {
      setUploading(true);
      const presignedData = await getPresignedUrl();

      const formData = new FormData();
      Object.entries(presignedData.fields).forEach(([key, value]) => {
        formData.append(key, value as string);
      });
      formData.append("file", file); // Append the file last

      await axios.post(presignedData.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event) => {
          if (!event.total) return;
          const percentCompleted = Math.round(
            (event.loaded * 100) / event.total
          );
          setProgress((prevProgress) => ({
            ...prevProgress,
            [fileFor]: percentCompleted,
          }));
        },
      });

      return {
        uid: file.uid,
        name: `${file.name}_${fileFor}`,
        status: "done" as UploadFileStatus,
        url: presignedData.url + "/" + presignedData.fields.key, // Construct the file URL
      };
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploading(false);
    }
  };

  interface CustomUploadChangeParam extends UploadChangeParam {
    fileFor: string;
  }

  const handleChange = async (info: CustomUploadChangeParam) => {
    if (uploading) {
      msg.info("Please wait for the current upload to finish");
      return;
    }
    let { file, fileFor } = info;
    if (file.status === undefined) {
      const convertedFile = await convertImageToPng(file as RcFile);
      const localPreview = URL.createObjectURL(convertedFile);
      setLocalPreviews((prev) => ({
        ...prev,
        [fileFor]: localPreview,
      }));

      const uploadedFile = await handleUpload(convertedFile as RcFile, fileFor);
      if (uploadedFile) {
        const updatedFileList = fileList.filter(
          (f) => !f.name.startsWith(fileFor + "_")
        );
        updatedFileList.push({
          ...uploadedFile,
          name: fileFor + "_" + uploadedFile.name,
        });
        setFileList(updatedFileList);
        setProgress((prevProgress) => ({
          ...prevProgress,
          [fileFor]: 0,
        }));
        setUploading(false);
      }
    } else if (file.status === "removed") {
      const updatedFileList = fileList.filter(
        (f) => !f.name.startsWith(fileFor + "_")
      );
      setFileList(updatedFileList);
      setProgress((prevProgress) => ({
        ...prevProgress,
        [fileFor]: 0,
      }));
    }
  };

  const filteredFileList = (fileFor: string) =>
    fileList.filter((f) => f.name.startsWith(fileFor + "_"));

  return (
    <>
      <div
        style={{
          color: "#909090",
          fontSize: "18px",
          margin: "10px 0 30px 0",
        }}
      >
        Please capture thorough images of the specified details on your sneakers
        to ensure a precise assessment.
      </div>
      {contextHolder}
      <Row>
        {filesDataArray.map((column) => (
          <Col
            span={6}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            key={column.fileFor}
          >
            <div className="upload-rectangle">
              <Upload
                onChange={(data) =>
                  handleChange({ ...data, fileFor: column.fileFor })
                }
                beforeUpload={() => false}
                showUploadList={{
                  showPreviewIcon: true,
                  showRemoveIcon: true,
                  showDownloadIcon: false,
                }}
                onPreview={handlePreview}
                progress={{ strokeWidth: 2, showInfo: false }}
              >
                {localPreviews[column.fileFor] ? (
                  <div className="flex flex-col items-center">
                    <img
                      width={100}
                      height={100}
                      src={localPreviews[column.fileFor]}
                      className="rounded-lg"
                    />
                    {progress[column.fileFor] < 100 &&
                    progress[column.fileFor] != 0 ? (
                      <Progress
                        percent={progress[column.fileFor]}
                        strokeWidth={2}
                        showInfo={false}
                        className="w-full px-2"
                      />
                    ) : null}
                  </div>
                ) : (
                  <div className="flex flex-col items-center">
                    <img
                      src={column.defaultImage}
                      alt="upload"
                      className="max-w-full"
                      style={{
                        height: column.height,
                      }}
                    />
                    {progress[column.fileFor] < 100 &&
                    progress[column.fileFor] != 0 ? (
                      <Progress
                        percent={progress[column.fileFor]}
                        strokeWidth={2}
                        showInfo={false}
                        className="w-full px-2"
                      />
                    ) : null}
                  </div>
                )}
              </Upload>
            </div>
            <span className="block text-center text-sm">
              {column.label}{" "}
              {column.required && <span className="text-red-500">*</span>}
            </span>
          </Col>
        ))}
      </Row>

      {/* {renderImageGrid()}
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal> */}
    </>
  );
};

export default UploadComp;
