import axios from "axios";
import type { RcFile } from "antd/es/upload";
import heic2any from "heic2any";

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const getPresignedUrl = async () => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/aws-s3/get-pre-signed-post-url-demo-assajk6223`,
      {
        type: "Watch",
      }
    );
    return response.data.data;
  } catch (error) {
    console.error("Error getting pre-signed URL from backend", error);
    throw error;
  }
};

export const convertImageToPng = async (file: RcFile) => {
  if (file.type === "image/heic") {
    const convertedBlob = await heic2any({
      blob: file,
      toType: "image/png",
    });
    const blob = Array.isArray(convertedBlob)
      ? convertedBlob[0]
      : convertedBlob;
    const convertedFile = new File(
      [blob],
      file.name.replace(/\.heic$/i, ".png"),
      {
        type: "image/png",
      }
    );
    return convertedFile;
  }
  if (file.type === "image/jpeg" || file.type === "image/jpg") {
    // For JPEG and JPG, we can convert them to PNG using a similar approach.
    const imageBitmap = await createImageBitmap(file);
    const canvas = document.createElement("canvas");
    canvas.width = imageBitmap.width;
    canvas.height = imageBitmap.height;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.drawImage(imageBitmap, 0, 0);
      const convertedBlob = await new Promise<Blob | null>((resolve) => {
        canvas.toBlob(resolve, "image/png");
      });
      if (convertedBlob) {
        const convertedFile = new File(
          [convertedBlob],
          file.name.replace(/\.(jpeg|jpg)$/i, ".png"),
          {
            type: "image/png",
          }
        );
        return convertedFile;
      }
    }
  }
  return file;
};
