import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Image, Progress, Tooltip } from "antd";
import { Row, Col, notification, Flex } from "antd";
import axios from "axios";
import React from "react";

interface IValue {
  average: number;
  max: number;
  min: number;
}

export const Report = (): JSX.Element => {
  const [queryParams, setQueryParams] = useState<Record<string, any>>({});
  const location = useLocation();
  const [api, contextHolder] = notification.useNotification();

  const { Grading_results } = queryParams;

  const openNotification = (text: string, type: string) => {
    if (type === "info")
      api.info({
        message: "Info",
        description: text,
        placement: "topRight",
      });
    else
      api.error({
        message: "Error",
        description: text,
        placement: "topRight",
      });
  };

  const getStrokeColor = (value: number) => {
    if (value == 10) {
      return "#30D96E";
    } else if (value == 9) {
      return "#12B347";
    } else if (value == 8) {
      return "#0F993E";
    } else {
      return "#D6B560";
    }
  };

  const removeSemiColonFromStartAndEnd = (str: string) => {
    if (str?.startsWith('"')) {
      str = str?.slice(1);
    }
    if (str?.endsWith('"')) {
      str = str?.slice(0, -1);
    }
    return str;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params: Record<string, any> = {};
    searchParams.forEach((value, key) => {
      try {
        params[key] = JSON.parse(decodeURIComponent(value));
      } catch (e) {
        params[key] = value;
      }
    });
    setQueryParams(params);
  }, [location]);

  const sendFeedbackBack = async (feedback: string) => {
    const axiosBody = {
      feedback: feedback,
      query_id: queryParams.query_id,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/grading/receive_feedback`,
      axiosBody
    );

    if (!response.data?.data?.success) {
      openNotification("Something went wrong. Try again later", "error");
      return;
    } else {
      openNotification("Feedback submitted successfully", "info");
    }
  };

  return (
    <div>
      {contextHolder}
      <div className="flex justify-left mt-12">
        <img
          className="object-contain cursor-pointer"
          alt="Sneaker Check logo black RBG"
          src="/logo.png"
          style={{ maxWidth: "120px", maxHeight: "126px" }}
          onClick={() => {
            window.location.href = "/";
          }}
        />
      </div>
      <div className="px-96 pt-20">
        <div id="top-part">
          <div className="[font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-[#333333] text-[40px] text-center tracking-[0] leading-[normal] whitespace-nowrap">
            Generated Report
          </div>
          <div className="[font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-black text-[29px] tracking-[0] leading-[normal] whitespace-nowrap">
            Identification
          </div>
          <img className="" alt="Line" src="/line-1.svg" />
          <div className="[font-family:'Lato-Bold',Helvetica] font-bold text-black text-[20px] tracking-[0] leading-[normal] whitespace-nowrap my-2">
            {removeSemiColonFromStartAndEnd(queryParams.Title)}
          </div>
          <p className="hidden [font-family:'Lato-Regular',Helvetica] font-normal text-[#909090] text-[16px] tracking-[0] leading-[23px]">
            The Sneaker is in {queryParams.sub_grades?.["Overall"] || "Good"}{" "}
            condition
          </p>

          <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
            {[
              { src: queryParams.image_url_left, alt: "Left" },
              { src: queryParams.image_url_right, alt: "Right" },
              { src: queryParams.image_url_front, alt: "Front" },
              { src: queryParams.image_url_heel, alt: "Heel" },
              { src: queryParams.image_url_bottom, alt: "Bottom" },
              { src: queryParams.image_url_inside, alt: "Inside" },
              { src: queryParams.image_url_tag, alt: "Tag" },
              { src: queryParams.image_url_box, alt: "Box" },
            ].map(
              (image, index) =>
                image.src && (
                  <div key={index} className="flex justify-center items-center">
                    <div className="relative w-full h-0 pb-[100%]">
                      <Image
                        // className="absolute top-0 left-0 w-full h-full object-contain"
                        className="h-full w-full object-cover"
                        alt={image.alt}
                        src={image.src.replace(
                          "ws-bucket-stag",
                          "ws-bucket-thumbnail-stag"
                        )}
                        fallback={image.src}
                        preview={false}
                      />
                    </div>
                  </div>
                )
            )}
          </div>
        </div>

        <div id="authentication" className="mt-20">
          <div className="[font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-[#333333] text-[29px] tracking-[0] leading-[normal] whitespace-nowrap">
            Authentication
          </div>
          <img className="" alt="Line" src="/line-1.svg" />

          <div className="flex mt-4 justify-between items-center">
            {queryParams.Authentication == "verified" ? (
              <div className="inline-block flex items-center">
                <img
                  className="inline bg-[#30D96E] w-[16px] h-[15px] p-[8px] rounded-[15px] mr-2"
                  alt="Check"
                  src="/check.svg"
                />
                <div className="inline mr-2 [font-family:'Lato-Bold',Helvetica] font-bold text-black text-[18px] tracking-[0] leading-[normal]">
                  Authentic
                </div>
              </div>
            ) : (
              <div className="inline-block flex items-center">
                <img
                  className="inline bg-[#FF3051] w-[16px] h-[15px] p-[8px] rounded-[15px] mr-2"
                  alt="Cross"
                  src="/cross.svg"
                />
                <div className="inline mr-2 [font-family:'Lato-Bold',Helvetica] font-bold text-black text-[18px] tracking-[0] leading-[normal]">
                  Counterfeit
                </div>
              </div>
            )}
            <div className=" hidden [font-family:'Lato-Regular',Helvetica] font-normal text-black text-[18px] tracking-[0] leading-[normal]">
              {queryParams.price} USD{" "}
              <span className="[font-family:'Lato-Regular',Helvetica] font-normal text-[#909090] text-[12px] tracking-[0] leading-[normal] whitespace-nowrap">
                est value
              </span>
            </div>
          </div>

          <div>
            <span className="[font-family:'Lato-Regular',Helvetica] font-normal text-[#909090] text-[12px] tracking-[0] leading-[normal] whitespace-nowrap">
              {removeSemiColonFromStartAndEnd(queryParams.Release_date)}
            </span>
          </div>

          <p className="[font-family:'Lato-Regular',Helvetica] font-normal text-[#909090] text-[14px] tracking-[0] leading-[18px] hidden">
            This report offers a detailed evaluation of sneakers, encompassing
            identification, authentication, and a numerical score on wear & tear
            covering color, fading, scratches, alterations, and damage. It also
            includes the year of release and information on the inclusion of
            original box and papers, along with an estimated market value in
            USD. While this assessment is based on visible conditions and
            information at the time of evaluation, it provides a valuable
            foundation for understanding the item's current state, encouraging
            buyers to utilize this information to make informed decisions.
          </p>
        </div>

        <div id="defect" className="mt-20 mb-10">
          <div className="top-[1140px] left-[339px] [font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-black text-[29px] tracking-[0] leading-[normal] whitespace-nowrap">
            Defect Recognition
          </div>
          <img
            className="top-[1188px] left-[339px] w-[101px] h-[2px]"
            alt="Line"
            src="/line-1.svg"
          />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex justify-center items-center">
              <div className="relative w-full h-0 pb-[100%]">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover rounded-[6px]"
                  alt="Rectangle"
                  src={`${Grading_results?.Left_map}`}
                />
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="relative w-full h-0 pb-[100%]">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover rounded-[6px]"
                  alt="Rectangle"
                  src={`${Grading_results?.Right_map}`}
                />
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="relative w-full h-0 pb-[100%]">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover rounded-[6px]"
                  alt="Rectangle"
                  src={`${Grading_results?.Front_map}`}
                />
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="relative w-full h-0 pb-[100%]">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover rounded-[6px]"
                  alt="Rectangle"
                  src={`${Grading_results?.Heel_map}`}
                />
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="relative w-full h-0 pb-[100%]">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover rounded-[6px]"
                  alt="Rectangle"
                  src={`${Grading_results?.Bottom_map}`}
                />
              </div>
            </div>
          </div>
        </div>

        <div id="grades" className="">
          <Row className="text-center mb-4 flex justify-center">
            <Col span={24} className="flex justify-center">
              <div className="w-[633px] h-[110px] bg-[#eeeeee] rounded-[6px] border border-solid border-[#d9d9d9] flex items-center justify-between p-4">
                <div>
                  <div className="[font-family:'Lato-SemiBold',Helvetica] font-semibold text-black text-[14px] text-left tracking-[0] leading-[normal]">
                    Overall Grading
                  </div>
                  <div className="[font-family:'Lato-SemiBold',Helvetica] mt-2 text-black text-[14px] text-left tracking-[0] leading-[normal]">
                    {Grading_results?.Text_Grade}
                  </div>
                </div>

                <div className="[font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-center tracking-[0] leading-[normal] text-black text-[24px] whitespace-nowrap">
                  <Progress
                    type="circle"
                    percent={Grading_results?.Overall_grade * 10}
                    format={() => Grading_results?.Overall_grade || "NA"}
                    strokeColor={getStrokeColor(Grading_results?.Overall_grade)}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="text-center">
            <Col span={8} className="flex justify-center">
              <div className="w-[191px] h-[179px] bg-[#eeeeee] rounded-[6px] border border-solid border-[#d9d9d9] flex flex-col items-center justify-center relative">
                <div className="[font-family:'Lato-SemiBold',Helvetica] font-semibold text-black text-[14px] text-center tracking-[0] leading-[normal] absolute bottom-2">
                  Cracks
                </div>
                <div className="[font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-center tracking-[0] leading-[normal] text-black text-[24px] whitespace-nowrap">
                  <Progress
                    type="circle"
                    percent={Grading_results?.sub_grades?.["Cracks"] * 10}
                    format={() =>
                      Grading_results?.sub_grades?.["Cracks"] || "NA"
                    }
                    strokeColor={getStrokeColor(
                      Grading_results?.sub_grades?.["Cracks"]
                    )}
                  />
                </div>
              </div>
            </Col>
            <Col span={8} className="flex justify-center">
              <div className="w-[191px] h-[179px] bg-[#eeeeee] rounded-[6px] border border-solid border-[#d9d9d9] flex flex-col items-center justify-center relative">
                <div className="[font-family:'Lato-SemiBold',Helvetica] font-semibold text-black text-[14px] text-center tracking-[0] leading-[normal] absolute bottom-2">
                  Dirty
                </div>
                <div className="[font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-center tracking-[0] leading-[normal] text-black text-[24px] whitespace-nowrap">
                  <Progress
                    type="circle"
                    percent={Grading_results?.sub_grades?.["Dirty"] * 10}
                    format={() =>
                      Grading_results?.sub_grades?.["Dirty"] || "NA"
                    }
                    strokeColor={getStrokeColor(
                      Grading_results?.sub_grades?.["Dirty"]
                    )}
                  />
                </div>
              </div>
            </Col>
            <Col span={8} className="flex justify-center">
              <div className="w-[191px] h-[179px] bg-[#eeeeee] rounded-[6px] border border-solid border-[#d9d9d9] flex flex-col items-center justify-center relative">
                <div className="[font-family:'Lato-SemiBold',Helvetica] font-semibold text-black text-[14px] text-center tracking-[0] leading-[normal] absolute bottom-2">
                  Heel Drag
                </div>
                <div className="[font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-center tracking-[0] leading-[normal] text-black text-[24px] whitespace-nowrap">
                  <Progress
                    type="circle"
                    percent={Grading_results?.sub_grades?.["Heel Drag"] * 10}
                    format={() =>
                      Grading_results?.sub_grades?.["Heel Drag"] || "NA"
                    }
                    strokeColor={getStrokeColor(
                      Grading_results?.sub_grades?.["Heel Drag"]
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="text-center mt-6">
            <Col span={8} className="flex justify-center">
              <div className="w-[191px] h-[179px] bg-[#eeeeee] rounded-[6px] border border-solid border-[#d9d9d9] flex flex-col items-center justify-center relative">
                <div className="[font-family:'Lato-SemiBold',Helvetica] font-semibold text-black text-[14px] text-center tracking-[0] leading-[normal] absolute bottom-2">
                  Nicks
                </div>
                <div className="[font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-center tracking-[0] leading-[normal] text-black text-[24px] whitespace-nowrap">
                  <Progress
                    type="circle"
                    percent={Grading_results?.sub_grades?.["Nicks"] * 10}
                    format={() =>
                      Grading_results?.sub_grades?.["Nicks"] || "NA"
                    }
                    strokeColor={getStrokeColor(
                      Grading_results?.sub_grades?.["Nicks"]
                    )}
                  />
                </div>
              </div>
            </Col>
            <Col span={8} className="flex justify-center">
              <div className="w-[191px] h-[179px] bg-[#eeeeee] rounded-[6px] border border-solid border-[#d9d9d9] flex flex-col items-center justify-center relative">
                <div className="[font-family:'Lato-SemiBold',Helvetica] font-semibold text-black text-[14px] text-center tracking-[0] leading-[normal] absolute bottom-2">
                  Stains
                </div>
                <div className="[font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-center tracking-[0] leading-[normal] text-black text-[24px] whitespace-nowrap">
                  <Progress
                    type="circle"
                    percent={Grading_results?.sub_grades?.["Stains"] * 10}
                    format={() =>
                      Grading_results?.sub_grades?.["Stains"] || "NA"
                    }
                    strokeColor={getStrokeColor(
                      Grading_results?.sub_grades?.["Stains"]
                    )}
                  />
                </div>
              </div>
            </Col>
            <Col span={8} className="flex justify-center">
              <div className="w-[191px] h-[179px] bg-[#eeeeee] rounded-[6px] border border-solid border-[#d9d9d9] flex flex-col items-center justify-center relative">
                <div className="[font-family:'Lato-SemiBold',Helvetica] font-semibold text-black text-[14px] text-center tracking-[0] leading-[normal] absolute bottom-2">
                  Stitching
                </div>
                <div className="[font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-center tracking-[0] leading-[normal] text-black text-[24px] whitespace-nowrap">
                  <Progress
                    type="circle"
                    percent={Grading_results?.sub_grades?.["Stitching"] * 10}
                    format={() =>
                      Grading_results?.sub_grades?.["Stitching"] || "NA"
                    }
                    strokeColor={getStrokeColor(
                      Grading_results?.sub_grades?.["Stitching"]
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-6 text-center flex justify-center">
            <Col span={8} className="flex justify-center">
              <div className="w-[194px] h-[108px] bg-[#eeeeee] rounded-[4px] border border-solid border-[#d9d9d9] flex flex-col items-center justify-center relative">
                <div className="[font-family:'Lato-Bold',Helvetica] font-bold tracking-[-0.41px] leading-[22px] text-black text-[24px] whitespace-nowrap">
                  {queryParams?.Release_date?.split("/")[2] || "N/A"}
                </div>
                <div className="[font-family:'Lato-Regular',Helvetica] font-normal text-[#909090] text-[18px] tracking-[-0.41px] leading-[normal]">
                  Year
                </div>
              </div>
            </Col>
            <Col span={8} className="flex justify-center hidden">
              <div className="w-[196px] h-[108px] bg-[#eeeeee] rounded-[4px] border border-solid border-[#d9d9d9] flex flex-col items-center justify-center relative">
                <div className="[font-family:'Lato-Bold',Helvetica] font-bold text-black text-[24px] tracking-[-0.41px] leading-[22px] whitespace-nowrap">
                  {queryParams.included_box == "true"
                    ? "Included"
                    : "Not Included"}
                </div>
                <div className="[font-family:'Lato-Regular',Helvetica] font-normal text-[#909090] text-[18px] tracking-[-0.41px] leading-[normal]">
                  Box
                </div>
              </div>
            </Col>
            <Col span={8} className="flex justify-center hidden">
              <div className="w-[194px] h-[108px] bg-[#eeeeee] rounded-[4px] border border-solid border-[#d9d9d9] flex flex-col items-center justify-center relative">
                <div className="[font-family:'Lato-Bold',Helvetica] font-bold text-black text-[24px] tracking-[-0.41px] leading-[22px] whitespace-nowrap">
                  {queryParams.included_papers == "true"
                    ? "Included"
                    : "Not Included"}
                </div>
                <div className="[font-family:'Lato-Regular',Helvetica] font-normal text-[#909090] text-[18px] tracking-[-0.41px] leading-[normal]">
                  Papers
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div id="authentication-2" className="mt-20 hidden">
          <div className="font-extrabold [font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-[#333333] text-[29px] tracking-[0] leading-[normal] whitespace-nowrap">
            Result A
          </div>
          <img className="" alt="Line" src="/line-1.svg" />

          <div className="flex mt-4 justify-between items-center">
            {queryParams.Result_A_shoes?.state == "verified" ? (
              <div className="inline-block flex items-center">
                <img
                  className="inline bg-[#30D96E] w-[16px] h-[15px] p-[8px] rounded-[15px] mr-2"
                  alt="Check"
                  src="/check.svg"
                />
                <div className="inline mr-2 [font-family:'Lato-Bold',Helvetica] font-bold text-black text-[18px] tracking-[0] leading-[normal]">
                  Authentic
                </div>
              </div>
            ) : (
              <div className="inline-block flex items-center">
                <img
                  className="inline bg-[#FF3051] w-[16px] h-[15px] p-[8px] rounded-[15px] mr-2"
                  alt="Cross"
                  src="/cross.svg"
                />
                <div className="inline mr-2 [font-family:'Lato-Bold',Helvetica] font-bold text-black text-[18px] tracking-[0] leading-[normal]">
                  Counterfeit
                </div>
              </div>
            )}
            <div className="[font-family:'Lato-Regular',Helvetica] font-normal text-black text-[18px] tracking-[0] leading-[normal] border-solid border-2 border-slate-200 p-[12px] rounded-[8px]">
              Confidence Score
              <span className="ml-2 [font-family:'Lato-Regular',Helvetica] font-normal text-[#0F993E] text-[24px] tracking-[0] leading-[normal] whitespace-nowrap">
                <Tooltip
                  title={queryParams.Result_A_shoes?.score}
                  color="#D6B560"
                >
                  {queryParams.Result_A_shoes?.score?.toFixed(2)}
                </Tooltip>
              </span>
            </div>
          </div>

          <div className="flex mt-4 justify-between items-center">
            <div className="[font-family:'Lato-Regular',Helvetica] font-normal text-black text-[12px] tracking-[0] leading-[normal] border-solid border-2 border-slate-200 p-[4px] rounded-[12px]">
              Overall Avergae
              <span className="ml-2 [font-family:'Lato-Regular',Helvetica] font-normal text-[#D6B560] text-[14px] tracking-[0] leading-[normal] whitespace-nowrap">
                <Tooltip
                  title={queryParams.Result_details_shoe?.overall_avg}
                  color="#D6B560"
                >
                  {queryParams.Result_details_shoe?.overall_avg?.toFixed(2)}
                </Tooltip>
              </span>
            </div>
            <div className="[font-family:'Lato-Regular',Helvetica] font-normal text-black text-[12px] tracking-[0] leading-[normal] border-solid border-2 border-slate-200 p-[4px] rounded-[12px]">
              Overall Max
              <span className="ml-2 [font-family:'Lato-Regular',Helvetica] font-normal text-[#D6B560] text-[14px] tracking-[0] leading-[normal] whitespace-nowrap">
                <Tooltip
                  title={queryParams.Result_details_shoe?.overall_max}
                  color="#D6B560"
                >
                  {queryParams.Result_details_shoe?.overall_max.toFixed(2)}
                </Tooltip>
              </span>
            </div>
            <div className="[font-family:'Lato-Regular',Helvetica] font-normal text-black text-[12px] tracking-[0] leading-[normal] border-solid border-2 border-slate-200 p-[4px] rounded-[12px]">
              Overall Min
              <span className="ml-2 [font-family:'Lato-Regular',Helvetica] font-normal text-[#D6B560] text-[14px] tracking-[0] leading-[normal] whitespace-nowrap">
                <Tooltip
                  title={queryParams.Result_details_shoe?.overall_min}
                  color="#D6B560"
                >
                  {queryParams.Result_details_shoe?.overall_min.toFixed(2)}
                </Tooltip>
              </span>
            </div>
          </div>
        </div>

        <div id="authentication-3" className="mt-20 hidden">
          <div className="font-extrabold [font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-[#333333] text-[29px] tracking-[0] leading-[normal] whitespace-nowrap">
            Result B
          </div>
          <img className="" alt="Line" src="/line-1.svg" />

          <div className="flex mt-4 justify-between items-center">
            <div className="flex items-center">
              {queryParams.Result_B_ROI?.state == "verified" ? (
                <div className="inline-block flex items-center">
                  <img
                    className="inline bg-[#30D96E] w-[16px] h-[15px] p-[8px] rounded-[15px] mr-2"
                    alt="Check"
                    src="/check.svg"
                  />
                  <div className="inline mr-2 [font-family:'Lato-Bold',Helvetica] font-bold text-black text-[18px] tracking-[0] leading-[normal]">
                    Authentic
                  </div>
                </div>
              ) : (
                <div className="inline-block flex items-center">
                  <img
                    className="inline bg-[#FF3051] w-[16px] h-[15px] p-[8px] rounded-[15px] mr-2"
                    alt="Cross"
                    src="/cross.svg"
                  />
                  <div className="inline mr-2 [font-family:'Lato-Bold',Helvetica] font-bold text-black text-[18px] tracking-[0] leading-[normal]">
                    Counterfit
                  </div>
                </div>
              )}
            </div>
            <div className="[font-family:'Lato-Regular',Helvetica] font-normal text-black text-[18px] tracking-[0] leading-[normal] border-solid border-2 border-slate-200 p-[12px] rounded-[8px]">
              Confidence Score
              <span className="ml-2 [font-family:'Lato-Regular',Helvetica] font-normal text-[#D6B560] text-[24px] tracking-[0] leading-[normal] whitespace-nowrap">
                <Tooltip
                  title={queryParams.Result_B_ROI?.score}
                  color="#D6B560"
                >
                  {queryParams.Result_B_ROI?.score?.toFixed(2)}
                </Tooltip>
              </span>
            </div>
          </div>

          <div className="px-10">
            <div className="px-10">
              {Object.entries(queryParams.Result_details_ROI || {}).map(
                ([key, value]) => {
                  const typedValue = value as IValue;

                  return (
                    <React.Fragment key={key}>
                      <div className="mt-4 font-extrabold [font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-[#333333] text-[15px] tracking-[0] leading-[normal] whitespace-nowrap">
                        {key}
                      </div>
                      <div className="flex mt-4 justify-between items-center">
                        {Object.entries(typedValue).map(
                          ([subKey, subValue]) => (
                            <div
                              key={subKey}
                              className="[font-family:'Lato-Regular',Helvetica] font-normal text-black text-[12px] tracking-[0] leading-[normal] border-solid border-2 border-slate-200 p-[4px] rounded-[12px]"
                            >
                              {subKey.charAt(0).toUpperCase() + subKey.slice(1)}
                              <span className="ml-2 [font-family:'Lato-Regular',Helvetica] font-normal text-[#D6B560] text-[14px] tracking-[0] leading-[normal] whitespace-nowrap">
                                <Tooltip title={subValue} color="#D6B560">
                                  {subValue.toFixed(2)}
                                </Tooltip>
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </React.Fragment>
                  );
                }
              )}
            </div>
          </div>
        </div>

        <div id="feedback" className="mt-20 hidden">
          <div className="mt-4 flex justify-between">
            <button
              className="bg-transparent cursor-pointer hover:bg-green-500 text-green-700 font-semibold hover:text-white py-4 px-14 border border-green-500 hover:border-transparent rounded-[22px]"
              onClick={() => {
                sendFeedbackBack("Authentic");
              }}
            >
              Authentic
            </button>
            <button
              className="bg-transparent cursor-pointer hover:bg-red-500 text-red-700 font-semibold hover:text-white py-4 px-14 border border-red-500 hover:border-transparent rounded-[22px]"
              onClick={() => {
                sendFeedbackBack("Fake");
              }}
            >
              Fake
            </button>
            <button
              className="bg-transparent cursor-pointer hover:bg-black text-black-700 font-semibold hover:text-white py-4 px-14 border border-black-500 hover:border-transparent rounded-[22px]"
              onClick={() => {
                sendFeedbackBack("Unsure");
              }}
            >
              Unsure
            </button>
          </div>
        </div>

        <div id="footer" className="mt-20 flex justify-center">
          <button
            onClick={() => {
              window.location.href = "/upload";
            }}
            className="bg-blue-500 hover:bg-blue-500 cursor-pointer text-white font-semibold hover:text-white py-4 px-14 border border-blue-500 hover:border-transparent rounded-[22px]"
          >
            Authenticate Another Sneaker
          </button>
        </div>
      </div>

      <p className="[font-family:'Lato-Regular',Helvetica] font-normal text-black text-[14px] tracking-[0] leading-[normal]">
        <span className="[font-family:'Lato-Regular',Helvetica] font-normal text-black text-[14px] tracking-[0]">
          Powered by{" "}
        </span>
        <span className="[font-family:'Lato-Bold',Helvetica] font-bold">
          Wrist Shot
        </span>
      </p>
    </div>
  );
};
